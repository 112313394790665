import { template as template_b9f7cd3c9fed44a697f07bbc6c425142 } from "@ember/template-compiler";
const FKText = template_b9f7cd3c9fed44a697f07bbc6c425142(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
