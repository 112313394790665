import { template as template_3b5d9c759361499da08bcbf6875e5a29 } from "@ember/template-compiler";
const SidebarSectionMessage = template_3b5d9c759361499da08bcbf6875e5a29(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
