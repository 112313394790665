import { template as template_4349b95f2b974f59850ce151970b1972 } from "@ember/template-compiler";
const FKLabel = template_4349b95f2b974f59850ce151970b1972(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
