import { template as template_095f0863c5734a40947cd3d9b7701abd } from "@ember/template-compiler";
const WelcomeHeader = template_095f0863c5734a40947cd3d9b7701abd(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
